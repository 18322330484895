import { default as _404mifd0dEufkMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/404.vue?macro=true";
import { default as index3VXJc1QRbtMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/index.vue?macro=true";
import { default as index6TUrADrUq5Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/password-[type]/index.vue?macro=true";
import { default as indexvmVaxGPzrmMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/favourites/index.vue?macro=true";
import { default as index6Xq51lBZW9Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/hires-imgs/index.vue?macro=true";
import { default as index8ger7a4s1zMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/moodboards/index.vue?macro=true";
import { default as personal_45areaOxjOONvza9Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area.vue?macro=true";
import { default as indexFVMUdb4XMAMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile/change-password/index.vue?macro=true";
import { default as index7kbFGUh1e5Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile/info/index.vue?macro=true";
import { default as profileHCltoDKaFiMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile.vue?macro=true";
import { default as indexeOZ9rFNbwoMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/architects-and-designers/index.vue?macro=true";
import { default as index1E4cuzOlt3Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/contacts/index.vue?macro=true";
import { default as indexnLgSurEXRnMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/carousel/index.vue?macro=true";
import { default as indexWtonsR7EM6Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/divider/index.vue?macro=true";
import { default as index3kzYM2QYrYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/form/index.vue?macro=true";
import { default as indexhQ4PN0WN3fMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/grid/index.vue?macro=true";
import { default as indextk1Fq27hMMMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/launcher/index.vue?macro=true";
import { default as index6MpVvNLWe8Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/media/index.vue?macro=true";
import { default as indexuqMZPDCQ2rMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/text/index.vue?macro=true";
import { default as indexxNhPygSWbxMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/toolbar/index.vue?macro=true";
import { default as blockAWRblJrPN6Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block.vue?macro=true";
import { default as carousel_45pinRCwyyPZ667Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/carousel-pin.vue?macro=true";
import { default as composerdkQJBQiiiBMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/composer.vue?macro=true";
import { default as easeai2PdjwsEWMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/ease.vue?macro=true";
import { default as geolistS4H2GLAO8xMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/geolist.vue?macro=true";
import { default as indexRRF8djjPJOMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/index.vue?macro=true";
import { default as media_45optionsgO77USA39QMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/media-options.vue?macro=true";
import { default as surferqQUQ2AJfrWMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/surfer.vue?macro=true";
import { default as _91id_93Ak7k6S9h3BMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/design-stories/[id].vue?macro=true";
import { default as indexVPel6ZJpmKMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/faq/index.vue?macro=true";
import { default as index31ezfzGPTIMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/index.vue?macro=true";
import { default as indexQS0zQHw9RwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/cookie-policy/index.vue?macro=true";
import { default as indexaf60f9C7b0Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/copyright-disclaimer/index.vue?macro=true";
import { default as indexuw49PFdq74Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/privacy-policy/index.vue?macro=true";
import { default as indexnYDmMMm5dHMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/terms-of-sale/index.vue?macro=true";
import { default as index1P1U0fIkD0Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/whistleblowing/index.vue?macro=true";
import { default as indexwi9lphvXzGMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/company/index.vue?macro=true";
import { default as indexcTwIY0fy3uMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/mirage-experience/index.vue?macro=true";
import { default as index6Epsf0GhKFMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/porcelain-tiles/index.vue?macro=true";
import { default as indexpBYicDsJC4Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/resource/index.vue?macro=true";
import { default as indexppNo5hOddBMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/sustainability/index.vue?macro=true";
import { default as _91id_93SgwUd3ttcwMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/moodboards/[id].vue?macro=true";
import { default as _91id_939KuEbRAv0pMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/news/[id].vue?macro=true";
import { default as indexynYQiNBqi7Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/press/index.vue?macro=true";
import { default as indexGLXyNKGvx6Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/[id]/index.vue?macro=true";
import { default as indexlyR9FT7BQrMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/atelier/index.vue?macro=true";
import { default as _91id_93bUpfb8Vx4PMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/collections/[id].vue?macro=true";
import { default as indexp3qzSSWMh9Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/collections/index.vue?macro=true";
import { default as indexV5cX41g7VEMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/find-product/index.vue?macro=true";
import { default as _91id_93wpm3IR3FtMMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/products/style-and-mood/[id].vue?macro=true";
import { default as _91id_93JQDfsIwArCMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/projects/[id].vue?macro=true";
import { default as indexqeGYmegkC8Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/certifications/index.vue?macro=true";
import { default as indexSKk5SwoaseMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/collections/index.vue?macro=true";
import { default as indexy65L8mF52HMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/handbooks/index.vue?macro=true";
import { default as indexNe6h5f4C1OMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/monographs/index.vue?macro=true";
import { default as resourcessSRZkgSK8LMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/resources.vue?macro=true";
import { default as indexZguuSRpBjYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/search/index.vue?macro=true";
import { default as indexXHp1ePSI9LMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signin/index.vue?macro=true";
import { default as indexvjLEWyjJgQMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signin/password-recovery/index.vue?macro=true";
import { default as indexH0Ws8EBkTEMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/activated/index.vue?macro=true";
import { default as indexssKxjBOf2jMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/activation/index.vue?macro=true";
import { default as indexEAHQU1WiJ5Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/index.vue?macro=true";
import { default as index8SzVut1gbdMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/private/index.vue?macro=true";
import { default as indexSmlOiSeucoMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/professional/index.vue?macro=true";
import { default as indexeltEUD5MplMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/bathroom/index.vue?macro=true";
import { default as indexSHzzctpOj6Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/engineering/index.vue?macro=true";
import { default as indexxKjI5aLRkRMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/floor-and-wall-tiles/index.vue?macro=true";
import { default as indexovh4Hta1HYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/outdoor-and-gardening/index.vue?macro=true";
import { default as indexuhImhKGjnqMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/pools-and-wellness/index.vue?macro=true";
import { default as indexAAXOIL4IgjMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/tables-and-furniture/index.vue?macro=true";
import { default as index7MiQtzvE84Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/worktops/index.vue?macro=true";
import { default as indexPrWMF7ZCvfMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/store-locator/index.vue?macro=true";
import { default as indexpbTlDOIHIWMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/stories/design-stories/index.vue?macro=true";
import { default as indexpyol73gyHWMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/stories/news/index.vue?macro=true";
import { default as indexkELhjGh6XGMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/stories/projects/index.vue?macro=true";
import { default as indexYHxFSovrLYMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/stories/trends/index.vue?macro=true";
import { default as storieswoyhZZFmUKMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/stories.vue?macro=true";
import { default as indexMEU7gRBE32Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-after-sales-and-installation-technical-assistance/index.vue?macro=true";
import { default as indexQCgFWABpkQMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-commercial-consultancy/index.vue?macro=true";
import { default as indexy3gYR9AlZiMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-product-info-and-solutions/index.vue?macro=true";
import { default as index5M2PF0qWSdMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-after-sales-and-installation-technical-assistance/index.vue?macro=true";
import { default as indexSTn7cYQYjyMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-commercial-consultancy/index.vue?macro=true";
import { default as index6PU9VrCEJmMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-hr-images-request/index.vue?macro=true";
import { default as indexHfeNd9SDx0Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-product-info-and-solutions/index.vue?macro=true";
import { default as supportnQJbF7tJv2Meta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/support.vue?macro=true";
import { default as _91id_93q1heKXN62GMeta } from "/var/www/html/mirage/frontend/pages/[country]/[locale]/trends/[id].vue?macro=true";
import { default as indexNLsbifqmFOMeta } from "/var/www/html/mirage/frontend/pages/index.vue?macro=true";
export default [
  {
    name: "country-locale-404",
    path: "/:country()/:locale()/404",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/404.vue")
  },
  {
    name: "country-locale-account",
    path: "/:country()/:locale()/account",
    meta: index3VXJc1QRbtMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/index.vue")
  },
  {
    name: "country-locale-account-password-type",
    path: "/:country()/:locale()/account/password-:type()",
    meta: index6TUrADrUq5Meta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/password-[type]/index.vue")
  },
  {
    name: "country-locale-account-personal-area",
    path: "/:country()/:locale()/account/personal-area",
    meta: personal_45areaOxjOONvza9Meta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area.vue"),
    children: [
  {
    name: "country-locale-account-personal-area-favourites",
    path: "favourites",
    meta: indexvmVaxGPzrmMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/favourites/index.vue")
  },
  {
    name: "country-locale-account-personal-area-hires-imgs",
    path: "hires-imgs",
    meta: index6Xq51lBZW9Meta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/hires-imgs/index.vue")
  },
  {
    name: "country-locale-account-personal-area-moodboards",
    path: "moodboards",
    meta: index8ger7a4s1zMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/personal-area/moodboards/index.vue")
  }
]
  },
  {
    name: "country-locale-account-profile",
    path: "/:country()/:locale()/account/profile",
    meta: profileHCltoDKaFiMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile.vue"),
    children: [
  {
    name: "country-locale-account-profile-change-password",
    path: "change-password",
    meta: indexFVMUdb4XMAMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile/change-password/index.vue")
  },
  {
    name: "country-locale-account-profile-info",
    path: "info",
    meta: index7kbFGUh1e5Meta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/account/profile/info/index.vue")
  }
]
  },
  {
    name: "country-locale-architects-and-designers",
    path: "/:country()/:locale()/architects-and-designers",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/architects-and-designers/index.vue")
  },
  {
    name: "country-locale-contacts",
    path: "/:country()/:locale()/contacts",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/contacts/index.vue")
  },
  {
    name: "country-locale-demo-block",
    path: "/:country()/:locale()/demo/block",
    meta: blockAWRblJrPN6Meta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block.vue"),
    children: [
  {
    name: "country-locale-demo-block-carousel",
    path: "carousel",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/carousel/index.vue")
  },
  {
    name: "country-locale-demo-block-divider",
    path: "divider",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/divider/index.vue")
  },
  {
    name: "country-locale-demo-block-form",
    path: "form",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/form/index.vue")
  },
  {
    name: "country-locale-demo-block-grid",
    path: "grid",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/grid/index.vue")
  },
  {
    name: "country-locale-demo-block-launcher",
    path: "launcher",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/launcher/index.vue")
  },
  {
    name: "country-locale-demo-block-media",
    path: "media",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/media/index.vue")
  },
  {
    name: "country-locale-demo-block-text",
    path: "text",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/text/index.vue")
  },
  {
    name: "country-locale-demo-block-toolbar",
    path: "toolbar",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/block/toolbar/index.vue")
  }
]
  },
  {
    name: "country-locale-demo-carousel-pin",
    path: "/:country()/:locale()/demo/carousel-pin",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/carousel-pin.vue")
  },
  {
    name: "country-locale-demo-composer",
    path: "/:country()/:locale()/demo/composer",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/composer.vue")
  },
  {
    name: "country-locale-demo-ease",
    path: "/:country()/:locale()/demo/ease",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/ease.vue")
  },
  {
    name: "country-locale-demo-geolist",
    path: "/:country()/:locale()/demo/geolist",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/geolist.vue")
  },
  {
    name: "country-locale-demo",
    path: "/:country()/:locale()/demo",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/index.vue")
  },
  {
    name: "country-locale-demo-media-options",
    path: "/:country()/:locale()/demo/media-options",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/media-options.vue")
  },
  {
    name: "country-locale-demo-surfer",
    path: "/:country()/:locale()/demo/surfer",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/demo/surfer.vue")
  },
  {
    name: "country-locale-design-stories-id",
    path: "/:country()/:locale()/design-stories/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/design-stories/[id].vue")
  },
  {
    name: "country-locale-faq",
    path: "/:country()/:locale()/faq",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/faq/index.vue")
  },
  {
    name: "country-locale",
    path: "/:country()/:locale()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/index.vue")
  },
  {
    name: "country-locale-info-and-legal-cookie-policy",
    path: "/:country()/:locale()/info-and-legal/cookie-policy",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/cookie-policy/index.vue")
  },
  {
    name: "country-locale-info-and-legal-copyright-disclaimer",
    path: "/:country()/:locale()/info-and-legal/copyright-disclaimer",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/copyright-disclaimer/index.vue")
  },
  {
    name: "country-locale-info-and-legal-privacy-policy",
    path: "/:country()/:locale()/info-and-legal/privacy-policy",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/privacy-policy/index.vue")
  },
  {
    name: "country-locale-info-and-legal-terms-of-sale",
    path: "/:country()/:locale()/info-and-legal/terms-of-sale",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/terms-of-sale/index.vue")
  },
  {
    name: "country-locale-info-and-legal-whistleblowing",
    path: "/:country()/:locale()/info-and-legal/whistleblowing",
    meta: index1P1U0fIkD0Meta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/info-and-legal/whistleblowing/index.vue")
  },
  {
    name: "country-locale-mirage-world-company",
    path: "/:country()/:locale()/mirage-world/company",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/company/index.vue")
  },
  {
    name: "country-locale-mirage-world-mirage-experience",
    path: "/:country()/:locale()/mirage-world/mirage-experience",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/mirage-experience/index.vue")
  },
  {
    name: "country-locale-mirage-world-porcelain-tiles",
    path: "/:country()/:locale()/mirage-world/porcelain-tiles",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/porcelain-tiles/index.vue")
  },
  {
    name: "country-locale-mirage-world-resource",
    path: "/:country()/:locale()/mirage-world/resource",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/resource/index.vue")
  },
  {
    name: "country-locale-mirage-world-sustainability",
    path: "/:country()/:locale()/mirage-world/sustainability",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/mirage-world/sustainability/index.vue")
  },
  {
    name: "country-locale-moodboards-id",
    path: "/:country()/:locale()/moodboards/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/moodboards/[id].vue")
  },
  {
    name: "country-locale-news-id",
    path: "/:country()/:locale()/news/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/news/[id].vue")
  },
  {
    name: "country-locale-press",
    path: "/:country()/:locale()/press",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/press/index.vue")
  },
  {
    name: "country-locale-products-id",
    path: "/:country()/:locale()/products/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/[id]/index.vue")
  },
  {
    name: "country-locale-products-atelier",
    path: "/:country()/:locale()/products/atelier",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/atelier/index.vue")
  },
  {
    name: "country-locale-products-collections-id",
    path: "/:country()/:locale()/products/collections/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/collections/[id].vue")
  },
  {
    name: "country-locale-products-collections",
    path: "/:country()/:locale()/products/collections",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/collections/index.vue")
  },
  {
    name: "country-locale-products-find-product",
    path: "/:country()/:locale()/products/find-product",
    meta: indexV5cX41g7VEMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/find-product/index.vue")
  },
  {
    name: "country-locale-products-style-and-mood-id",
    path: "/:country()/:locale()/products/style-and-mood/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/products/style-and-mood/[id].vue")
  },
  {
    name: "country-locale-projects-id",
    path: "/:country()/:locale()/projects/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/projects/[id].vue")
  },
  {
    name: "country-locale-resources",
    path: "/:country()/:locale()/resources",
    meta: resourcessSRZkgSK8LMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources.vue"),
    children: [
  {
    name: "country-locale-resources-certifications",
    path: "certifications",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/certifications/index.vue")
  },
  {
    name: "country-locale-resources-collections",
    path: "collections",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/collections/index.vue")
  },
  {
    name: "country-locale-resources-handbooks",
    path: "handbooks",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/handbooks/index.vue")
  },
  {
    name: "country-locale-resources-monographs",
    path: "monographs",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/resources/monographs/index.vue")
  }
]
  },
  {
    name: "country-locale-search",
    path: "/:country()/:locale()/search",
    meta: indexZguuSRpBjYMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/search/index.vue")
  },
  {
    name: "country-locale-signin",
    path: "/:country()/:locale()/signin",
    meta: indexXHp1ePSI9LMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signin/index.vue")
  },
  {
    name: "country-locale-signin-password-recovery",
    path: "/:country()/:locale()/signin/password-recovery",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signin/password-recovery/index.vue")
  },
  {
    name: "country-locale-signup-activated",
    path: "/:country()/:locale()/signup/activated",
    meta: indexH0Ws8EBkTEMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/activated/index.vue")
  },
  {
    name: "country-locale-signup-activation",
    path: "/:country()/:locale()/signup/activation",
    meta: indexssKxjBOf2jMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/activation/index.vue")
  },
  {
    name: "country-locale-signup",
    path: "/:country()/:locale()/signup",
    meta: indexEAHQU1WiJ5Meta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/index.vue")
  },
  {
    name: "country-locale-signup-private",
    path: "/:country()/:locale()/signup/private",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/private/index.vue")
  },
  {
    name: "country-locale-signup-professional",
    path: "/:country()/:locale()/signup/professional",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/signup/professional/index.vue")
  },
  {
    name: "country-locale-solutions-bathroom",
    path: "/:country()/:locale()/solutions/bathroom",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/bathroom/index.vue")
  },
  {
    name: "country-locale-solutions-engineering",
    path: "/:country()/:locale()/solutions/engineering",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/engineering/index.vue")
  },
  {
    name: "country-locale-solutions-floor-and-wall-tiles",
    path: "/:country()/:locale()/solutions/floor-and-wall-tiles",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/floor-and-wall-tiles/index.vue")
  },
  {
    name: "country-locale-solutions-outdoor-and-gardening",
    path: "/:country()/:locale()/solutions/outdoor-and-gardening",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/outdoor-and-gardening/index.vue")
  },
  {
    name: "country-locale-solutions-pools-and-wellness",
    path: "/:country()/:locale()/solutions/pools-and-wellness",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/pools-and-wellness/index.vue")
  },
  {
    name: "country-locale-solutions-tables-and-furniture",
    path: "/:country()/:locale()/solutions/tables-and-furniture",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/tables-and-furniture/index.vue")
  },
  {
    name: "country-locale-solutions-worktops",
    path: "/:country()/:locale()/solutions/worktops",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/solutions/worktops/index.vue")
  },
  {
    name: "country-locale-store-locator",
    path: "/:country()/:locale()/store-locator",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/store-locator/index.vue")
  },
  {
    name: "country-locale-stories",
    path: "/:country()/:locale()/stories",
    meta: storieswoyhZZFmUKMeta || {},
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/stories.vue"),
    children: [
  {
    name: "country-locale-stories-design-stories",
    path: "design-stories",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/stories/design-stories/index.vue")
  },
  {
    name: "country-locale-stories-news",
    path: "news",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/stories/news/index.vue")
  },
  {
    name: "country-locale-stories-projects",
    path: "projects",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/stories/projects/index.vue")
  },
  {
    name: "country-locale-stories-trends",
    path: "trends",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/stories/trends/index.vue")
  }
]
  },
  {
    name: "country-locale-support",
    path: "/:country()/:locale()/support",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support.vue"),
    children: [
  {
    name: "country-locale-support-private-after-sales-and-installation-technical-assistance",
    path: "private-after-sales-and-installation-technical-assistance",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-after-sales-and-installation-technical-assistance/index.vue")
  },
  {
    name: "country-locale-support-private-commercial-consultancy",
    path: "private-commercial-consultancy",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-commercial-consultancy/index.vue")
  },
  {
    name: "country-locale-support-private-product-info-and-solutions",
    path: "private-product-info-and-solutions",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/private-product-info-and-solutions/index.vue")
  },
  {
    name: "country-locale-support-professional-after-sales-and-installation-technical-assistance",
    path: "professional-after-sales-and-installation-technical-assistance",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-after-sales-and-installation-technical-assistance/index.vue")
  },
  {
    name: "country-locale-support-professional-commercial-consultancy",
    path: "professional-commercial-consultancy",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-commercial-consultancy/index.vue")
  },
  {
    name: "country-locale-support-professional-hr-images-request",
    path: "professional-hr-images-request",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-hr-images-request/index.vue")
  },
  {
    name: "country-locale-support-professional-product-info-and-solutions",
    path: "professional-product-info-and-solutions",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/support/professional-product-info-and-solutions/index.vue")
  }
]
  },
  {
    name: "country-locale-trends-id",
    path: "/:country()/:locale()/trends/:id()",
    component: () => import("/var/www/html/mirage/frontend/pages/[country]/[locale]/trends/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/html/mirage/frontend/pages/index.vue")
  }
]